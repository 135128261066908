<template>
    <div id="commentWhatLoadingContainer">
        <div class="commentInfo">
            <div class="logo item"></div>
            <div class="points item"></div>
        </div>
        <div class="commentData">
            <div class="tester item"></div>
            <div class="date item"></div>
            <div class="comment item"></div>
            <div class="comment2 item"></div>
            <div class="answer">
                <div class="item"></div>
                <div class="item"></div>
                <div class="item"></div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.item {
    background: #e6e6e6;
    position: relative;
}
.item:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
    );
    animation: loading 0.8s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
#commentWhatLoadingContainer {
    margin-top: 20px;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 5px;
    height: fit-content;
    @media screen and (min-width: 1000px) {
        grid-template-rows: auto;
        grid-template-columns: auto 1fr;
    }
    .commentInfo {
        height: fit-content;
        .logo {
            width: 70px;
            height: 70px;
        }
        .points {
            height: 16px;
            width: 85px;
            margin-top: 5px;
        }
    }
    .commentData {
        height: fit-content;
        padding: 10px 15px;
        display: grid;
        grid-template-rows: auto auto auto auto auto;
        row-gap: 5px;
        @media screen and (min-width: 1000px) {
            padding-right: 20px;
        }
        .tester{
            width: 90px;
            height: 10px;
        }
        .date{
            width: 70px;
            height: 5px;
        }
        .comment{
            margin-top: 10px;
            width: 100%;
            height: 10px;
        }
        .comment2{
            width: 50%;
            height: 10px;
        }
        .answer{
            display: flex;
            margin-top: 10px;
            > div{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 5px;
                &:last-child{
                    width: 80px;
                    border-radius: 0px;
                }
            }
        }
    }
}
</style>